import { $ } from '@local/ts/lib/patchedJQuery';
import 'slick-carousel';

(() => {
    if (typeof $ !== 'function') {
        console.error('jQuery is not loaded');
        return;
    }

    if (typeof $.fn['slick'] !== 'function') {
        console.error('Slick is not loaded into jquery');
        return;
    }

    // configure sliders
    $(window).on('load', function () {
        $("[data-section-handler='SlideshowSection']").slick({
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 10000,
            pauseOnFocus: true,
            arrows: false,
            fade: true,
            cssEase: 'linear',
            adaptiveHeight: true
        });

        $("[data-section-handler='TestimonialsSection'] .reviews").slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 1000,
            slidesToShow: '1.25',
            centerMode: true,
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1
                    }
                },
            ]
        });
    });
})();