import "@local/sass/main.scss";

import GLightbox from "glightbox";
window.GLightbox = GLightbox;

import { $ } from '@local/ts/lib/patchedJQuery';
import {
  debounce,
  domReady,
  throttle,
  windowReady,
} from "@local/ts/lib/Utility";
import * as IMask from "@local/ts/interactions/InputMaskInteractions";
import * as DeltaScroll from "@local/ts/interactions/DeltaScrollInteractions";
import * as ElementToggleInteractions from "@local/ts/interactions/ElementToggleInteractions";
window["ElementToggleInteractions"] = ElementToggleInteractions;
import * as GLightboxInteractions from "@local/ts/interactions/GLightboxInteractions";
import { registerPolyfills } from "@local/ts/polyfills/register";
import '@local/ts/rawJS/slideshows.js';

domReady(() => {
  registerPolyfills();
  GLightboxInteractions.register();
  IMask.mask();
  DeltaScroll.register(50, 30, 60);
  ElementToggleInteractions.register();
  console.log("Prism JS loaded.");
});

window.jQuery = window.$ = $;

windowReady(() => {
  document.querySelector("body").classList.remove("disable-animations");
});

// TYPE DECLARATION
declare global {
  interface Window {
    jQuery: () => void;
    $: () => void;
  }
}